.my-trips-container {
    position: relative;
    left: 3em;
    width: 100%;
    padding-bottom: 100px;
    padding-right: 100px;
}

.my-account-container {
    position: relative;
    left: 3em;
    width: 100%;
    padding-bottom: 100px;
    padding-right: 100px;
}

.mytripsHeader {
    float: left;

}

.nav-tabs {
    border-bottom-color: #eeeeee;
}

.nav-tabs a:active {
    border-left: none;
}

.uib-tab.active a {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom-color: #e41566 !important;
    border-width: 3px !important;
}

.uib-tab a {
    text-transform: uppercase;
    padding-top: 100px;
    border: 1px solid #000;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 4.38;
    letter-spacing: 3.1px;
    text-align: center;
    color: #292929;
    border-top: none;
    border-right: none;
    border-left: none;

    min-width: 200px;
    width: 20%;
}

.uib-tab.active a {
    /**/
}

.riderListFilter>th {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
    text-align: left;
    color: #626262;
    color: var(--black);
    text-transform: uppercase;
    height: 63px;
    padding-bottom: 15px !important;
}

#cancelTripButton {
    width: 118px;
    height: 35px;
    object-fit: contain;
    border-radius: 2px;
    background-color: #fff;
    border: solid 2px #3996d2;
    float: right;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: 0.1px;
    color: #555555;
    color: var(--greyish-brown);
}

.tripDetail {
    font-family: Poppins;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: 0.1px;
    color: #555555;
    vertical-align: middle !important;
}

.rideInfoRow {
    height: 110px;

}

.rideInfoRow:hover {
    background-color: #1e75bc;
}

.rideInfoRow:hover>td {
    background-color: #1e75bc;
    color: white;
    cursor: pointer;
}

//targets the bootstrap table
.table thead>tr>th {
    border-bottom: none;
}

.nav-tabs>li>a {
    border: none;
}

.my-trips-heading {
    float: left;
    width: 116px;
    height: 17px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.16;
    letter-spacing: 0.5px;
    text-align: left;
    color: #555555;
    text-transform: uppercase;
    margin-bottom: 80px;
}

#my-trips-head-container {
    width: 100%;
    float: left;
}



.cancelJourneyResultMessage {
    height: 70px;
    width: 100%;
    float: right;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: #2c2c2c;
}

.exitConfirmDelButton {
    border-radius: 3px;
    background-color: white;
    border: solid 2px #22295f;
    width: 162px;
    height: 35px;
}

.exitConfirmDel {
    padding-top: 100px;
    height: 50px;
    width: 100%;
    float: left;
    padding-left: 33%;
}

.cancelJournSucc {
    width: 90px;
    height: 90x;
    margin-left: 40%;
}

.cancelJournIconCont {
    width: 100%;
    height: 100px;
}

.cancelTripButton {
    width: 229px;
    height: 46px;
    background-color: #1e75bc;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 15px;
    font-size: 15px;
    margin-bottom: 30px;
    clear: both;
    margin-top: 10px;
    border-radius: 3.5px;
}

.cancelTripButton:hover {
    background-color: #3f97d0 !important;
}

.cancelJourneyModalInit {
    width: 100%;
    float: left;
    padding: 21px;
    background-color: #fff;
    min-height: 300px;
}

#addFacHead {
    text-align: center;
}

.cancelJourneyButtons {
    padding-top: 140px;
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
}

.cancelJournConf {
    width: 100px;
    height: 46px;
    float: left;
    background-color: #1e75bc;
    color: white;
    border: none;
}

.confirmJournConf {
    width: 100px;
    height: 46px;
    background-color: #1e75bc;
    color: white;
    border: none;
    float: right;
}

.cancelJourneyHeader {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: #2c2c2c;
}

.canJourneyRes {
    width: 100%;
    float: left;
    padding: 21px;
    background-color: #fff;
    min-height: 300px;
}

// RID-2592 - START
$trip-view-status-green: #008000;
$trip-view-status-green-light: #edf6ed;

$trip-view-status-blue: #007c8a;
$trip-view-status-blue-light: #ebf2f4;

$trip-view-status-red: #d70400;
$trip-view-status-red-light: #f8e6e8;

div.trip-view-topheader {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    padding-left: 20px;
    padding-top: 10px;
}

// all general styling
div[id^="trip-details-"] {
    div.trip-view-top-details {
        font-family: Poppins;

        div.row {
            margin: 0;
        }

        #trip-view-detailStatus {
            margin: 10px 0 10px 0;

            span {
                font-size: 18px;
            }

            span.trip-view-status {
                font-weight: 600;
            }
        }

        #trip-view-infoBox {
            padding: 12px;

            span {
                float: left;
                padding-right: 6px;
            }


            p {
                font-weight: 500;
                margin: 0px;
            }
        }

        #trip-view-dateHeader {
            margin-top: 10px;

            span.details-row {
                font-size: 18px;

                span {
                    font-weight: 500;
                }
            }
        }
    }

    #trip-view-pickup-info {
        span {
            font-weight: 600;
        }

        span.glyphicon {
            font-size: 15px;
        }
    }
}

// all "BLUE" status styling
#trip-details-blue {
    #trip-view-detailStatus {
        span.trip-view-status {
            color: $trip-view-status-blue;
        }
    }

    #trip-view-infoBox {
        background-color: $trip-view-status-blue-light;
        border: $trip-view-status-blue 1px solid;

        p {
            color: $trip-view-status-blue;
        }
    }

    #trip-view-pickup-info {
        span {
            color: $trip-view-status-blue;
        }
    }
}

// all "green" statuses
#trip-details-green {
    #trip-view-detailStatus {
        span.trip-view-status {
            color: $trip-view-status-green;
        }
    }

    #trip-view-infoBox {
        background-color: $trip-view-status-green-light;
        border: $trip-view-status-green 1px solid;

        p {
            color: $trip-view-status-green;
        }
    }

    #trip-view-pickup-info {
        span {
            color: $trip-view-status-green;
        }
    }
}

// all "red" statuses
#trip-details-red {
    #trip-view-detailStatus {
        span.trip-view-status {
            color: $trip-view-status-red;
        }
    }

    #trip-view-infoBox {
        background-color: $trip-view-status-red-light;
        border: $trip-view-status-red 1px solid;

        p {
            color: $trip-view-status-red;
        }
    }

    #trip-view-pickup-info {
        span {
            color: $trip-view-status-red;
        }
    }
}

// RID-2592 - END