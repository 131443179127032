.landing-container {
  background-color: #232c5e;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main-logo-row {
  margin-top: 10em;
}

.main-landing-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.landing-title1-row {
  text-align: center;
  margin-top: 2em;
}

.landing-title1-row span {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14.1px;
  line-height: 1.5;
  letter-spacing: 3.7px;
  text-align: center;
  color: #ffffff;
}

.landing-btn-row {
  text-align: center;
  margin-top: 2em;
}

.landing-btn-row button {
  border-radius: 0;
  border: 3.5px solid #3f97d0;
  background-color: #232c5e;
  width: 163px;
  height: 55px;
}

.landing-btn-row button span {
  font-family: Montserrat;
  font-size: 12.3px;
  font-weight: bold;
  line-height: 3.17;
  letter-spacing: 3px;
  color: white;
}

.landing-btn {
  margin-left: 1em;
}

.landing-btn:hover {
  background-color: #3f97d0 !important;
}

.landing-download-row span{
  position: relative;
  right: 6em;
  width: 98px;
  height: 10px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14.1px;
  line-height: 1.9;
  color: #ffffff;
}

.landing-download-row {
  text-align: center;
  margin-top: 4em;
}

.landing-download-row img {
  width: 144.4px;
  height: 45px;
  margin-left: 1em;
}

.landing-foot-logo-row img {
  width: 70%;
  height: 70%;
}

.foot-img-row img{
  position: relative;
  top: -20em;
  float: right;
}

.foot-text-row {
  margin-left: 3em;
  margin-top: 4em;
}

.foot-text-1 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 3px;
  color: #ffffff;
}

.foot-text-1 span {
  color: #72A9E9;
}

.foot-text-2 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
}

.foot-text-3 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}

@media (max-width: 1170px) {
  .foot-img-row img {
    display: none;
  }
}

.error-txt {
  color: red;
}