.ng-ui-view{
  font-family: Lato-Light;
}
.fill {
  min-height: 100%;
  height: 100%;
}

.left-col {
  background-color: #2477b9;
}

.left-col .row{
  margin: auto;
  text-align: center;
}

.left-col .rider-logo {
  margin-top: 23%;
}

.left-col .sub-text-1 {
  margin-top: 6%;
  width: 160px;
}

.left-col .sub-text-1  span {
  font-size: 2em;
  font-weight: 300;
  line-height: 1.11;
  letter-spacing: -2px;
  text-align: center;
  color: #ffffff;
}

.left-col .street-scene {
  margin-top: 7%;
  width: 62%;
  height: 15%;
}

.left-col .sub-text-2 {
    margin-top: 6%;
}

.left-col .sub-text-2 span {
  font-weight: 500;
  font-size: 1.7em;
  font-weight: 100;
  line-height: 1.88;
  text-align: center;
  color: #ffffff;
}

.left-col .sub-text-3 {
    margin-top: 7%;
    min-width:339px;
}

.left-col .sub-text-3 span{
  font-family: Open-Sans;
  font-weight: 400;
  opacity: 0.6;
  font-size: 10px;
  font-weight:400;
  line-height: 1.7;
  width: 339px;
  color: #ffffff;
}

.right-col {
  height: 100%;
  background-color: #ffffff;
}

.right-col .sign-area {
  margin-top: 6em;
}

.right-col .sign-area .signin-title-txt {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 2.2;
  letter-spacing: -0.2px;
  color: #626262;
}

.right-col .sign-area .row {
  margin-left: 16%;
}

.right-col .sign-area .email {
  margin-top: 4%;
}

.right-col .sign-area .input-rectangle {
  max-width: 336px;
  border-style: solid;
  border-color: #D3D3D3;
  border-width: 2px;
}

.right-col .sign-area .login-input-lable {
  margin-left: 4%;
  margin-top: 4%;
  font-size: 12px;
  font-weight: 600;
  line-height: 2.75;
  color: #626262;
}

.right-col .sign-area .login-input-pos {
  margin-left: 4%;
  width: 300px;
}

.right-col .sign-area .transparent-input {
  background-color:rgba(0,0,0,0) !important;
   border:none !important;
}

.right-col .sign-area .password {
  margin-top: 2%;
}

.right-col .sign-area .login-option {
  margin-top: 2%;
}

.right-col .sign-area .keep-signin-txt {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #555555;
  color: var(--greyish-brown);
}

.right-col .sign-area .terms-of-service {
  font-weight: 900;
}

.right-col .sign-area .pwd-reset {
  position: relative;
  left: 8em;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #e11e67;
}

.right-col .sign-area .signin-btn {
  margin-top: 3%
}

.right-col .sign-area .btn-size {
  width: 96px;
  height:35px;
  background-color: #2477b9;
}

.right-col .icon-row {
  margin-top: 2em;
  margin-left: 10%;
}
.right-col .icon-row .right-icon-col {
  margin-left: 1em;
  margin-top: 0.5em;
}

.right-col .icon-row .additional-spec {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  max-width: 248px;
}

.sign-area input {
  width: auto;
}

.sign-area .row img {
  max-width: 340px;
}

.register-login-info {
  position: relative;
  left: 13em;
}

.login-reg-info {
  position: relative;
  left: 10.5em;
}

.reg-login-link {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  max-width: 248px;
}

.reg-login-row, .reg-email-row, .login-reg-row, .login-email-row{
  margin-top: 0 !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:focus {
    outline:none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-dialog {
  .pwd-reset {
    color: #e11e67;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.2px;
    margin-bottom: 15px;
    margin-left: 23px;
  }
}