@font-face {
  font-family: Lato-Light;
  src: url('../fonts/lato/Lato-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Lato;
  src: url('../fonts/lato/Lato-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}