@import 'fonts/Lato';
@import 'fonts/Poppins';
@import 'fonts/Open-Sans';
@import 'fonts/Montserrat';
@import 'reg-login';
@import 'bootstrap';
@import 'book-trip';
@import 'navbar';
@import 'animate';
@import 'landing';
@import 'dashboard';
@import 'trip-list';
@import 'my-profile';
@import 'ts-and-cs';
@import 'contact-form';
@import 'view-next-trip';
@import 'more';
@import 'modals';
@import url('https://js.arcgis.com/3.20/esri/css/esri.css');