html, body {
  height: 100%;
  min-width: 100%;

    .ng-ui-view {
      height: 100%;
      width: 100%;
    }
}

.rider-partial {
  padding: 0;
  margin: 0;
  width: auto;
  min-height: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.inline {
  display: inline-block;
}

.show-left-border {
  border-left: solid 2px rgba(230, 230, 230, 0.7);
}

.row-header {
  margin: 0;
  padding-top: 9px;
  padding-bottom: 1em;
  width: auto;
  height: 50px;
  min-width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  .username-info{
    text-align: right;
  }
  div{
    padding-left: 0px;
  }
}

.main-logo {
  margin-left: 20px;
  max-height: 34px;
}

.ham-icon {
  margin-top: 6px;
  margin-left: 7px;
  cursor: pointer;
  background-image: url(../images/header/ic-menubar-black@2x.png);
  height: 24px;
  width: 35px;
  background-size: 100%;
  background-repeat: no-repeat;
  float: left;
}

.search-icon {
  max-height: 20px;
  max-width: 20px;
  margin-top: 0.3em;
}

.search-input {
  padding-left: 0.5em;
  padding-top: 0.3em;
  border: 0;
  box-shadow: none;
  vertical-align: middle;
  width: 80%;
}

.search-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-size:    1.1em;
}
.search-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   font-size:    1.1em;
}
.search-input::-moz-placeholder { /* Mozilla Firefox 19+ */
   font-size:    1.1em;
}
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   font-size:    1.1em;
}

.profile-img {
  max-width: 30px;
  max-height: 32px;
  margin-left: 0.5em;
}

.profile-more-icon {
  margin-left: 0.5em;
}

.row-body {
  min-height: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  overflow-y: auto;
  border-top: solid 2px rgba(230, 230, 230, 0.7);
  .content-page{
    .row{
      margin-top: 0;
    }
  }
  ui-view.row{
    margin: 0px;
    display: block;
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.username {
  font-size: 16px;
  color: #626262;
}

.side-nav {
  position: relative;
  width: 50px;
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 80px;
  padding-top: 0px;
  list-style: none;
  background-color: #2b303b;
  padding-left:0px;
  padding-right:0px;
  float: left;
  background-color: #141414;
}


.side-nav a {
  text-decoration: none;
}

.content-page {
  height: 100%;
}

.menu-row {
  height: 50px;
  min-width: 50px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -1px;
  margin-bottom: 0px;
  cursor: pointer;
}

.toggle-nav {
  list-style: none;
  padding-left: 0;
}

.toggle-nav .menu-row {
  margin-left:0;
  margin-right:0;
  width: 14em;
}

.toggle-nav > li .col-menu-title{
  width: 9em;
  padding-right: 0em;
}

.toggle-nav > li .col-menu-icon{
  padding-left: 2em;
}

li {
  cursor: pointer;
  cursor: hand;
}

.title-1 {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: #dddddd;
  display: none;
  margin-left:10px;
}

.col-menu-title {
  padding-top: 0.8em;
  width: 11em;
}

.col-menu-icon {
  //width: auto;
}



.menu-icon-icon{
  width: 25px;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  float:left;
}

.menu-icon-label {
  background-color: #2a2a2a;
  z-index: 10;
  position: relative;
  left: 50px;
  height: 49px;
  width: 0%;
  color:#ffffff;
  transition: width 0.2s;
  line-height: 49px;
  .submenu-container{
    position: relative;
    top: 35px;
  }
  &.submenu{
    background-color: #2a2a2a;
    position: static;
  }
}

.menu-icon-buy-pass,
.menu-icon-my-payments {
  .submenu-container{
    top: 0px;
  }
}

.menu-icon-label-text {
  opacity:0; 
  transition:1s;
  min-width: 200px;
  display: none;
  position: absolute;
  left: 10px;
  color:#FFFFFF;
}

.menu-icon-icon{
  background-position-y: 11px;
  margin-left: 12px;
}


  .menu-icon-book-trip:hover {
      background-color:#ffffff !important;
  } 
  


.menu-icon-area{
  width: 100%;
  height: 50px;

  &:hover {

    .menu-icon-label {
      top: 1px;
      width: 200px;    
    }

    .menu-icon-label-text {
        opacity:1; 
        transition:1s;
        min-width: 200px;
        display: block;
        position: absolute;
        left: 10px;
        color:#FFFFFF;
      }
  }


  &.menu-icon-home, 
  &.menu-icon-book-trip, 
  &.menu-icon-my-account, 
  &.menu-icon-next-trip, 
  &.menu-icon-my-trips,
  &.menu-icon-more,
  &.menu-icon-logout,
  &.menu-icon-contact,
  &.menu-icon-notifications,
  &.menu-icon-settings,
  &.menu-icon-search,
  &.menu-icon-my-payments,
  &.menu-icon-buy-pass 
  {
    &:hover, &:focus, &:focus-within{
      background-color:#ffffff !important;
      .menu-icon-label{
        width: 200px;
        .menu-icon-label-text {
          opacity:1; 
          display: inherit;
        }
      }
    } 
    &.active{
      background-color:#ffffff !important;
    }
  }

  &.menu-icon-home{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-home-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-home-black@2x.png');
      }
    }  
  }


  &.menu-icon-book-trip{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-book-white@2x.png');
      background-position-y: 17px;
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-book-black@2x.png');
      }
    }    
  }
  &.menu-icon-my-account{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-customer-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-customer-black@2x.png');
      }
    } 
  }


  &.menu-icon-next-trip{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/next-trip-white.svg');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/next-trip-black.svg');
      }
    }
  }

  &.menu-icon-my-trips{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-trip-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-trip-black@2x.png');
      }
    }
  }
  &.menu-icon-buy-pass{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/buy-pass-white.svg');
    }
    &:hover, &:focus, &:focus-within, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/buy-pass-black.svg');
      }
    }
    .menu-icon-label{
      height: 0px;
      padding-top: 0px;
      &.submenu {
        height: 49px;
      }
    }
    .submenu-container{
      margin-top: 0px;
    }
    .menu-icon-label.submenu {
      height: 42px;
    }
  }
  &.menu-icon-more{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-more-white@2x.png');
    }
    &:hover, &:focus, &:focus-within, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-more-black@2x.png');
      }
    }
  }

  &.menu-icon-contact{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-contact-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-contact-black@2x.png');
      }
    }
  }

  &.menu-icon-notifications{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-notification-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-notification-black@2x.png');
      }
    }
  }


  &.menu-icon-settings{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-settings-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-settings-black@2x.png');
      }
    }
  }
  &.menu-icon-search{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-search-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-search-black@2x.png');
      }
    }
  }

  &.menu-icon-my-payments{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-payment-white@2x.png');
    }
    &:hover, &:focus, &:focus-within, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-payment-black@2x.png');
      }
    }
    .menu-icon-label{
      height: 0px;
      &.submenu {
        height: 49px;
      }
    }
    .submenu-container{
      margin-top: 0px;
    }
    .menu-icon-label.submenu {
      height: 42px;
    }
  }
 

  &.menu-icon-logout{
    .menu-icon-icon{
      background-image: url('../images/nav_icons/ic-logout-white@2x.png');
    }
    &:hover, &:focus, &.active{
      .menu-icon-icon{
        background-image: url('../images/nav_icons/ic-logout-black@2x.png');
      }
    }
  }


  .menu-icon-terms, .menu-icon-policies, .menu-icon-about{
    &:hover,&:focus,&:focus-within, &.active{
     border-left:1px solid #FFFFFF;
    }
    a:focus{
      border-left:1px solid red;
     }
  }
}

.custom-nav-button {
  width: 48px;
  height: 48px;
  background-color: transparent;
}

.parent-list-item {
  padding-top: 0px;
  height: 50px;
}

.toggle-list-item {
  padding-top: 0px;
}

.logout-icon {
  width: 18px;
  height: 21px;
}

.login-timeout {
  color: red;
}

.version-info{
  color:#FFFFFF;
}

.menu-item {
    &:hover {

    .label {
      width: 250px;
    }

    .label-text {
      display: block;
    }
  }
}