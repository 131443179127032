.dash-rec {
  min-width: 357px;
  width: auto;
  min-height: 354px;
  height: auto;
}

.dash-container {
  position: relative;
  max-width: 1182px;
  width: 100%;
  padding-bottom: 100px;
  padding-right: 70px;
  padding-top: 50px;
  padding-left: 70px;
}

.dash-book-trip {
  background-color: #e41566;
  text-align: center;
}

.dash-text {
  margin: 0 auto;
  height: 64px;
  line-height: 1.28;
  margin-top: 130px;
  max-width: 300px;
}

.dash-main-text {
  text-align: center !important;
  display: table;
}

.dash-text span {
  font-family: Poppins;
  font-size: 34.2px;
  font-weight: 300;
  color: #ffffff;
}

.dash-book-trip img {
  position: relative;
  top: 115px;
  width: 64px;
  height: 87px;
}

.dash-about img {
  position: relative;
  top: 115px;
  height: 87px;
}

.dash-about {
  background-color: #e41566;
  text-align: center;
}

.dash-trips {
  background-color: #414ca0;
  text-align: center;
}

.dash-trips img {
  position: relative;
  top: 115px;
  width: 77px;
  height: 92px;
}

.dash-account {
  background-color: #1e75bc;
  text-align: center;
}

.dash-account img {
  position: relative;
  top: 115px;
  width: 84px;
  height: 84px;
}

.dash-account {
  background-color: #222a5f;
  text-align: center;
}

.dash-policies img {
  position: relative;
  top: 115px;
  width: 79px;
  height: 88px;
}

.dash-logo{
  text-align: center;
  background-image: url("../images/dash-logo-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;

}
dash-invisible {
  display: none;
}

.dash-passes img {
  position: relative;
  top: 115px;
}

.dash-passes {
  text-align: center;
  background-color: #929292;
}

.dash-logo img {
  position: relative;
  top: 115px;
}

.dash-contacts {
  background-color: #646f89;
  text-align: center;
}

.dash-contacts img {
  position: relative;
  top: 115px;
  width: 72px;
  margin-bottom: 19px;
}

.dash-account-text {
  width: 163px !important;
  left: 90px !important;
}

.dash-pay-text {
  width: 158px !important;
  left: 90px !important;
}

#dash-row-tile :hover {
  cursor:pointer;
}


.dash-my-trips {
    background-color: #1e75bc;
    text-align: center;
  }
  
  .dash-my-trips img {
    position: relative;
    top: 115px;
    width: 77px;
    height: 92px;
  }

  @media (max-width: 768px) {
    .dash-container {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .username-info{
      display: none;
    }
  }
