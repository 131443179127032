
#profileHeader {
  width: 300px;
  height: 17px;
  opacity: 0.7;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.16;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform:uppercase;
  margin-bottom:19px;
};

#info-card {
	background-color: #222a5f;
	color: white;
	height: 150px;
  	object-fit: contain;
  	margin-bottom: 42px;
  	width:100%;
  	padding-left:100px;
}

#buttonRow {
	text-align: center;
	height:46px;
	width:100%;
	float: left;
}

#riderAccDet {
	width:100%;
}

#pro-account-details{
	width: 100%;
}

#pro-acc-det-header {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  text-align: left;
  color: #323237;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 30px;
}

#myacc-but-trips {
  width: 156.1px;
  height: 46px;
  object-fit: contain;
  border-radius: 2px;
  background-color: #fff;
  border: solid 2px #3996d2;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: 0.1px;
  color: #555555;
  padding-bottom: 1px;
  margin-right: 46px;
}

#myacc-but-book {
  background-color: #1e75bc;
  border: solid 1px #1e75bc;
  width: 156.1px;
  height: 46px;
  object-fit: contain;
  background-color: #1e75bc;
  color: white;
  font-family: Poppins;
  font-size: 16.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Top-Table-Line {
  width: 100%;
  height: 1px;
  object-fit: contain;
  background-color: #323237;
}

#cuspro-acc-details {
	width:100%;
	float:left;
}

.cuspro-acc-det-key{
  padding-left: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 7;

  color: #2c2c2c;

}

.cuspro-acc-det-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 7;
  letter-spacing: 0.1px;
  text-align: left;
  color: #555555;
}

.password-edit {
  margin-left: 40px;
  background-color: white;
}
.password-box {
  width: 100px;
  height: 25px;
  line-height: 10px;
}
.password-div {
  height: 50px;
}
.pwd-div {
  height: 60px;
}

.cusdet-hold {
	border-bottom: 2px solid #eeeeee;
}

#infcard-custAdd {
  margin-top: 5px;
  font-family: Arial;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: -0.2px;
  text-align: left;
}

#infcard-custId {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: left;
}

#infcard-mutuser {
	height: 12px;
  opacity: 0.8;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: left;
}
