#termsContent, #privacyContent, #aboutContent  {
    p, div, li{
        font-family: 'Lato', Arial !important;
        font-size: 14px !important;
        text-align: left !important;
        font-weight: 400 !important;
        line-height: 21px !important;
        color: #141414;
    }
    h1, h2{
        font-family: 'Lato', Arial !important;
        text-align: left !important;
        font-size: 16px !important;
        line-height: 30px !important;
        font-weight: 600 !important;
        color: #141414;
    }
}
