.book-trip-container {
    width: 100%;
    position: relative;
    padding-bottom: 100px;
    padding-right: 100px;
    left: 3em;
}

.row {
    margin-top: 1em;
}

.btn:focus,
.btn:active {
    outline: none !important;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}

.book-trip-pageTitle {
    font-family: Poppins;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.16;
    letter-spacing: 0.5px;
    color: #555555;
}

.select-trip-INFO {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 0.92;
    letter-spacing: 0.1px;
    color: #626262;
}

.calendar-span {
    width: 262px;
    height: 61px;
    background-color: #ffffff;
    border: solid 1px #555555;
}

.form-control {
    background-color: #ffffff !important;
    box-shadow: inset 0 0 0 !important;
}

.custom-calendar {
    border: 0;
}

// .calendar-span input:-moz-read-only { /* For Firefox */
//     background-color: transparent;
// }

.text-info {
    color: inherit;
}

.custom-btn {
    border: 0px;
    padding-bottom: 0;
}

.calendar-des {
    width: 191px;
}

.calendar-are {
    border: 1px solid;
}

.calendar-img {
    width: 32px;
    height: 34px;
    position: relative;
    left: 1em;
    top: 0.7em;
    display: block;
    margin: 0;
}

.calendar-title {
    position: relative;
    width: 194px;
    left: 3em;
    font-family: Poppins;
    font-size: 12.1px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: #626262;
}

.timetype-title {
    position: relative;
    width: 194px;
    left: 3em;
    top: 0.5em;
    font-family: Poppins;
    font-size: 12.1px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: #626262;
}

.mandatory {
    color: red;
    position: relative;
    right: 0.3em;
    top: 0.3em;
}

.calendar-group {
    float: right;
    width: 191px;
    position: relative;
    bottom: 0.7em;
    right: 0.6em;
}

.time-pick-choice {
    position: relative;
    top: 0.5em;
    left: 0.5em;
    font-family: Poppins;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 0.8;
    color: #626262;
}

.time-choice {
    position: relative;
    border: none;
    width: 100px;
    top: 0.8em;
    left: 3.7em;
    font-family: Poppins;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 0.9;
    color: #626262;
}

.time-span {
    width: 200px;
    height: 61px;
    background-color: #ffffff;
    border: solid 1px #555555;
    margin-left: 3.5em;
}

.time-img {
    width: 29px;
    height: 29px;
    position: relative;
    left: 1em;
    top: 0.9em;
    display: block;
    margin: 0;
}

.time-title {
    position: relative;
    width: 91px;
    left: 2em;
    top: 0.3em;
    font-family: Poppins;
    font-size: 12.1px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: #626262;
}

.time-mandatory {
    color: red;
    position: relative;
    float: right;
    right: 1em;
    top: 0.7em;
}

.custom-time-btn {
    position: relative;
    float: right;
}

.custom-time-picker {
    margin-top: 1em;
    left: 2em;
    width: 173px;
    padding-left: 0.6em;
}

.location-group {
    width: 191px;
    position: relative;
    bottom: 0.7em;
    right: 0.6em;
    //float: left;
}

.addr-span {
    width: 460px;
    height: 60px;
    background-color: #ffffff;
    border: solid 1px #555555;
}

.addr-title {
    position: relative;
    width: 284px;
    left: 3.2em;
    font-family: Poppins;
    font-size: 12.1px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: #626262;
}

.location-img {
    width: 26px;
    height: 37px;
    position: relative;
    left: 1em;
    top: 0.7em;
    display: block;
    margin: 0;
}

.addr-choice {
    height: 13px;
    font-family: Poppins;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 0.8;
    color: #626262;
    position: relative;
    left: 4.5em;
    top: 0.2em;
    overflow: hidden;
    white-space: nowrap;
    min-width: 350px;
    height: 25px;
    border: 0;
}

.timetype-choice {
    height: 13px;
    font-family: Poppins;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 0.8;
    color: #626262;
    position: relative;
    left: 4.1em;
    top: 0.2em;
    overflow: hidden;
    border: 0;
}

.custom-timetype-btn {
    position: relative;
    float: right;
    top: 0.3em;
    right: 1em;
}

.custom-addr-btn {
    position: relative;
    float: right;
    top: 0.5em;
}

.custom-addr-picker {
    position: relative;
    top: 2em;
    left: 3.5em;
    width: auto;
}

.mobility-type-title {
    position: relative;
    width: 284px;
    top: 0.5em;
    left: 3.2em;
    font-family: Poppins;
    font-size: 12.1px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: #626262;
}

.mobility-type-choice {
    height: 13px;
    font-family: Poppins;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 0.8;
    color: #626262;
    position: relative;
    left: 4.5em;
    top: 0.5em;
    overflow: hidden;
    white-space: nowrap;
    width: 330px;
    height: 25px;
    border: 0;
}

.custom-mobility-type-btn {
    position: relative;
    float: right;
    top: 0.8em;
}

.custom-stage-btn-1 {
    width: 193px;
    height: 48px;
    border-radius: 24px;
    border: 1.5px solid #555555;
    float: left;
}

.custom-stage-btn-2 {
    width: 193px;
    height: 48px;
    border-radius: 24px;
    border: 1.5px solid #555555;
    margin-left: 200px;
}

.custom-stage-btn-1 span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 4.07;
    position: relative;
    top: -0.8em;
}

.custom-stage-btn-2 span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 4.07;
    position: relative;
    top: -0.8em;
}

.custom-stage-btn:hover {
    background-color: #222a5f !important;

    span {
        color: #ffffff;
    }
}

// second stage

.traveller-device-title {
    position: relative;
    top: 1em;
    font-family: Poppins;
    font-size: 14.4px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.1px;
    color: #000000;
}

.attendant-device-title {
    position: relative;
    top: 2em;
    font-family: Poppins;
    font-size: 14.4px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.1px;
    color: #000000;
}

.device-span {
    width: 400px;
    height: 60px;
    background-color: #ffffff;
    border: solid 1px #555555;
}

.device-span img {
    position: relative;
    top: 1em;
    left: 1em;
    width: 26px;
    height: 29px;
}

.trip-people-counter {
    width: 110px;
    height: 33px;
    border: 2px solid #555555;
    border-radius: 25px;
    margin-top: 1.5em;
}

.trip-people-counter button {
    border: none;
    background: none;
}

.trip-people-counter input {
    border: none;
    background: none;
}

#trip-notes {
    width: 647px;
    height: 121px;
}

.confirm-row {
    min-width: 730px;
    height: auto;
    border: solid 1px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    ;
}

.confirm-row .row {
    margin-left: auto;
    margin-right: auto;
}

.status-row span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 3.5;
    letter-spacing: 0.3px;
    color: #2c2c2c;
}

.status-row span span {
    color: #e41566;
}

.details-row {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.75;
    color: #2c2c2c;
}

.trip-location {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 700;
    line-height: 1.32;
    color: #626262;
}

.trip-location span {
    font-family: Poppins;
    font-weight: 400;
}

.confirm-trip-breakDown {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.23;
    letter-spacing: 0.3px;
    color: #2c2c2c;
}

.panel,
.panel-body {
    border: 0px !important;
    box-shadow: 0 0 0 !important;
}

.confirm-row .breakdown-row {
    margin-top: 2em;
}

.breakdown-row .panel-heading {
    background-color: #222a5f !important;
}

.breakdown-row .subtitle {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.1px;
    color: #ffffff;
}

.breakdown-row .custom-breakdown-icon {
    color: #ffffff !important;
}

.travel-row .panel-heading {
    background-color: #ccd1d3 !important;
}

.travel-row .subtitle {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.1px;
    color: #2c2c2c;
}

.trip-breakdown-row {
    margin-top: 1.5em;
}

.trip-breakdown-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.1px;
    color: #000000;
}

.blue-alert {
    width: 99px;
    height: 22px;
    background-color: #414ca0;
    text-align: center;
}

.red-alert {
    width: 99px;
    height: 22px;
    background-color: #e41566;
    text-align: center;
}

.blue-alert,
.red-alert span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 2.25;
    color: #ffffff;
}

.walk-icon {
    width: 20px;
    height: 43px;
    position: relative;
    left: 1em;
}

.alert-title {
    color: #e41566;
}

.alert-title,
.travel-itinerary-instruction,
.travel-itinerary-txt {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    letter-spacing: 0.1px;
}

.travel-itinerary-table img {
    margin-left: auto;
    margin-right: auto;
}

.confirm-row .btn-row {
    margin-bottom: 2em;
}

.success-container {
    text-align: center;
}

.success-container .row {
    margin-top: 2em;
}

.img-row img {
    width: 163px;
    height: 153px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.success-subtext1 {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 0.57;
    letter-spacing: 0.1px;
    color: #000000;
}

.success-subtext2 {
    font-family: Poppins;
    font-weight: 400;
    font-size: 17px;
    line-height: 0.71;
    letter-spacing: 0.1px;
    color: #626262;
}

.rider-mobility-type {
    position: relative;
    margin-left: 1em;
    font-family: Poppins;
    font-weight: 400;
    font-size: 15.6px;
    line-height: 0.86;
    color: #626262;
}

.wheelchair-img {
    width: 26px;
    height: 29px;
}

.dropdown-float {
    position: absolute;
    z-index: 1000;
}

@media (min-width: 1200px) {
    .stage-bar {
        padding-top: 2em;
        display: block;
        width: 1170px;
    }

    .circle {
        margin: auto;
        background: #e41566;
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
    }

    .vertical-line {
        margin-top: -4.5em;
        width: 16%;
        border: 1px solid #e41566;
        vertical-align: middle;
        width: 270px;
    }

    .tabNo {
        position: relative;
        top: 0.4em;
        font-size: 1em;
        color: #ffffff;
    }

    .stage-text {
        font-family: Poppins;
        font-size: 0.8em;
        font-weight: 700;
        line-height: 3;
        letter-spacing: 1px;
        text-align: center;
        color: #292929;
    }
}

@media (min-width: 991px) {
    .ham-icon-row img {
        display: none;
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    .stage-bar {
        padding-top: 2em;
        display: block;
        width: 920px;
    }

    .circle {
        margin: auto;
        background: #e41566;
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
    }

    .vertical-line {
        margin-top: -4.5em;
        width: 16%;
        border: 1px solid #e41566;
        vertical-align: middle;
        width: 220px;
    }

    .tabNo {
        position: relative;
        top: 0.4em;
        font-size: 1em;
        color: #ffffff;
    }

    .stage-text {
        font-family: Poppins;
        font-size: 0.8em;
        font-weight: 700;
        line-height: 3;
        letter-spacing: 1px;
        text-align: center;
        color: #292929;
    }
}

@media (max-width: 990px) {
    .stage-bar {
        padding-top: 2em;
        display: block;
        width: 750px;
    }

    .circle {
        margin: auto;
        background: #e41566;
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
    }

    .vertical-line {
        margin-top: -4.5em;
        width: 16%;
        border: 1px solid #e41566;
        vertical-align: middle;
        width: 130px;
    }

    .tabNo {
        position: relative;
        top: 0.4em;
        font-size: 1em;
        color: #ffffff;
    }

    .stage-text {
        font-family: Poppins;
        font-size: 0.8em;
        font-weight: 700;
        line-height: 3;
        letter-spacing: 1px;
        text-align: center;
        color: #292929;
    }
}

.glyphicon-chevron-down {
    color: #337ab7;
}

.bookTripError {
    position: relative;
    top: 1em;
    color: #e41566;
}

.attendant {
    margin: 5px;
}

/** RID-3092: Amble portal should support scrollable list of addresses that fit on page for pickup/dropoff address **/
ul.dropdown-menu {
    max-height: 270px;
    overflow: auto;
    /* to get scroll */
}