.eta {
    position: absolute; 
    right: 30px; 
    top: 60px; 
    background-color: black; 
    color: white; 
    height: 39.5px; 
    border-radius: 100px; 
    width: 121.5px;
} 

.map-data-unavailable {
    position: absolute; 
    right: 33%; 
    top: 755px; 
    background-color: black; 
    color: white; 
    height: 39.5px; 
    border-radius: 100px; 
    width: 321.5px;
} 

.map-data-unavailable-text {
    position: absolute; 
    top: 10px;
    left: 95px;
}

.panel-default {
    position: relative;
}

.image {
    padding-bottom: 7px; 
    padding-left: 7px; 
    padding-top: 7px; 
    padding-right: 4px;
}

.eta-text {
    position: absolute; 
    top: 1px; 
}

.eta-time {
    position: absolute; 
    top: 16px; 
}
