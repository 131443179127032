#tAndCHead {
	text-transform: uppercase;
	margin-left: 10px;
	margin-bottom: 20px;
	font-family: Poppins;
	font-weight: 600;
}

.my-ts-and-cs-container{
    width: 100%;
    padding-bottom: 100px;
    padding-right: 100px;
}

.my-ts-and-cs-container > p {
    margin-left: 10px;
	font-family: Arial;
	font-size: 16px;
	color: #444444;
}

.my-ts-and-cs-container li {
	margin-left: 10px;
	font-family: Arial;
	cursor : default;
	font-size: 16px;
	color: #444444;
}

.modal{
	display: block !important;
}

.modal-dialog{
	overflow-y: initial !important;
}

.modal-body{
	height: 400px;
	overflow-y: auto;
}
