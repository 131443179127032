@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins/Poppins-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}