@font-face {
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Regular.ttf');
  font-style: normal;
}