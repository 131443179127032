.modal-content {
    border-radius: 0px;
    font-family: "Lato", Arial, sans-serif;
    height: auto;
}

.close-icon {
    cursor: pointer;
    height: 21px;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 21px;
}

.info-icon {
    height: 78px;
    position: absolute;
    top: 84px;
    left: calc(50% - 39px);
    object-fit: contain;
    width: 78px;
}

#request-email-modal-form,
.no-trips-modal {
    
    h3,
    .heading {
        color: #000000;
        font-size: 18px;
        margin-bottom: 9px;
        margin-top: 204px;
        text-align: center;
        text-transform: uppercase;
    }

    h3 {
        margin-top: 45px;
    }

    .message {
        color: #727272;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
    }
}

#request-email-modal-form {
    .modal-header {
        border: none;
        padding-bottom: 15px;
    }

    .modal-body {
        padding-top: 0;
        height: 250px;
    }

    .email {
        border-color: #D3D3D3;
        border-style: solid;
        border-width: 2px;
        box-sizing: border-box;
        display: block;
        padding-left: 10px;
        margin: 15px auto 30px auto;
        height: 60px;
        width: 90%;
    }

    .btn {
        background-color: #2477b9;
        color: #ffffff;
        display: block;
        margin: 0 auto;
        height: 35px;
        width: 140px;
    }
}

#email-verification-modal{
    .modal-body{
        height: 100px;
    }
}