#contact-title {
	color: rgba(85, 85, 85, 0.7);
	margin-left: 154px;
	height: 27px;
  opacity: 0.7;
  font-family: Poppins;
  font-size: 24.2px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: 0.4px;
}

#contact-info-banner {
	margin-top: 18px;
	background-color: #222a5f;
	color: #ffffff;
	padding-top: 34px;
	font-family: Arial;
	height: 196px;
	padding-left: 154px;
}

#contact-info-banner-sec-one {
	width:40%;
	float: left;
}

#contact-info-banner-sec-two {
	width:60%;
	float: left;
}

.contact-info-banner-big-type {
	font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: left;
}

.contact-info-banner-small-type {

	height: 13px;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  text-align: left;
}

#contact-info-banner-extra {
	width:100%;
	font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top:95px;

}

#contact-phy-addr {
	line-height: 9px;

	float: left;
	font-size: 13px;
	padding-top: 7px;
}

#contact-info-main-head {
  height: 18px;
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: left;
  min-width: 300px;
  margin-bottom: 20px;
}

#contactInfoMain {
	padding-left: 154px;
  font-family: Poppins;
  font-weight: 600;
}

#contactInfoMain > div > div > p {
	font-family: Arial;

}

#contactWebLink {
	color: #0645AD;
	cursor: pointer;
}

.contact-info-head-split {
  width: 100%;
  float: left;
}

.contact-info-head-sub-split-one {
  width: 9%;
  float: left;
}

.contact-info-head-sub-split-two {
  width: 60%;
}
